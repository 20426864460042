"use client";

import { FC, useEffect } from "react";

import { usePathname } from "next/navigation";

import { EventNames as NREventNames } from "~/constants/new-relic";
import { getMessageFromGraphQLError } from "~/helpers/get-message-from-graphql-error";

import { ServiceErrorPageProps } from "./types";

export const NewRelicIntegration: FC<ServiceErrorPageProps> = (
  props: ServiceErrorPageProps,
) => {
  const { statusCode = 500, error = new Error("error") } = props;
  const pathname = usePathname();

  useEffect(() => {
    if (statusCode && error) {
      // eslint-disable-next-line no-restricted-globals
      window.newrelic?.addPageAction(NREventNames.renderOopsPage, {
        statusCode,
        error:
          getMessageFromGraphQLError(error) ||
          `Unhandled error ${JSON.stringify(error)}`,
        stack: error?.stack,
      });
    }
  }, [pathname, statusCode, error]);

  return null;
};
