import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

import { SERVICE_ERROR_PAGE_CLASSES } from "~/components/service-error-page/constants";
import { getFormatMedia } from "~/theme/utils";

export const ServiceErrorPageContainer = styled(Container)(({ theme }) => {
  const { BREAKPOINT_TABLET } = getFormatMedia(theme);

  return {
    [`& .${SERVICE_ERROR_PAGE_CLASSES.logo}`]: {
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
      marginTop: theme.spacing(4),
      height: 16,
      [BREAKPOINT_TABLET]: {
        marginTop: theme.spacing(7),
        height: 28,
      },

      // temporary fix to problem with "PENNEYS" logo size
      // additional information is provided in description of PR
      "& img": {
        width: "auto",
      },
    },
  };
});
