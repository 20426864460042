import { ApolloError, isApolloError } from "@apollo/client";

export const getMessageFromGraphQLError = (error: ApolloError | Error): string => {
  if (!isApolloError(error) || !error.graphQLErrors) {
    return error.message;
  }

  const { graphQLErrors, networkError, clientErrors } = error as ApolloError;

  if (graphQLErrors?.length) {
    return graphQLErrors.map((error) => error.message).join(", ");
  }
  if (clientErrors?.length) {
    return clientErrors.map((error) => error.message).join(", ");
  }

  if (networkError) {
    return networkError.message;
  }

  return error.message;
};
