"use client";

import React, { FC } from "react";

import Typography from "@mui/material/Typography";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import {
  SERVICE_ERROR_INFO_CLASSES,
  SERVICE_ERROR_INFO_TRANSLATIONS,
} from "~/components/service-error-page/components/service-error-info/constants";
import { ServiceErrorInfoContainer } from "~/components/service-error-page/components/service-error-info/styled";
import { getTestAutomationProps } from "~/helpers/test-automation-props";

export interface ServiceErrorInfoProps {
  className?: string;
}

export const ServiceErrorInfo: FC<ServiceErrorInfoProps> = () => {
  const locale = useParams<GlobalPathParams>()?.locale;

  const { title, text } =
    SERVICE_ERROR_INFO_TRANSLATIONS[locale] ||
    SERVICE_ERROR_INFO_TRANSLATIONS["en-gb"];

  return (
    <ServiceErrorInfoContainer item xs={12} sm={8}>
      <Typography
        className={SERVICE_ERROR_INFO_CLASSES.title}
        {...getTestAutomationProps("title")}
      >
        {title}
      </Typography>
      <Typography
        className={SERVICE_ERROR_INFO_CLASSES.text}
        {...getTestAutomationProps("text")}
      >
        {text}
      </Typography>
    </ServiceErrorInfoContainer>
  );
};
