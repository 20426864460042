import React from "react";

import { ApolloError } from "@apollo/client";

import { LinkedLogo } from "~/components/logo/components/linked-logo/component";
import { NewRelicIntegration } from "~/components/service-error-page/components/new-relic-integration/component";
import { SERVICE_ERROR_PAGE_CLASSES } from "~/components/service-error-page/constants";
import { ServiceErrorPageContainer } from "~/components/service-error-page/styled";
import { Routes } from "~/constants/request";
import { getTestAutomationProps } from "~/helpers/test-automation-props";

import { ServiceErrorInfo } from "./components/service-error-info/component";

interface ServiceErrorPageProps {
  statusCode?: number;
  error?: Error | ApolloError;
}

export function ServiceErrorPage(props: ServiceErrorPageProps) {
  return (
    <ServiceErrorPageContainer {...getTestAutomationProps("page-500")}>
      <NewRelicIntegration {...props} />
      <LinkedLogo
        className={SERVICE_ERROR_PAGE_CLASSES.logo}
        size="large"
        href={Routes.HOME}
      />
      <ServiceErrorInfo />
    </ServiceErrorPageContainer>
  );
}
