import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import { SERVICE_ERROR_INFO_CLASSES } from "~/components/service-error-page/components/service-error-info/constants";
import { getFormatMedia } from "~/theme/utils";

export const ServiceErrorInfoContainer = styled(Grid)(({ theme }) => {
  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = getFormatMedia(theme);

  return {
    maxWidth: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",

    [`& .${SERVICE_ERROR_INFO_CLASSES.title}`]: {
      ...theme.typography.h4,
      marginBottom: theme.spacing(2),
      textAlign: "center",
      [BREAKPOINT_TABLET]: {
        ...theme.typography.h3,
        textAlign: "center",
        marginBottom: theme.spacing(3),
      },
      [BREAKPOINT_DESKTOP]: {
        ...theme.typography.h2,
        textAlign: "center",
        marginBottom: theme.spacing(4),
      },
    },

    [`& .${SERVICE_ERROR_INFO_CLASSES.text}`]: {
      marginBottom: theme.spacing(4),
      ...theme.typography.body1,
      textAlign: "center",
      [BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(5),
        ...theme.typography.h6,
        textAlign: "center",
      },
    },
  };
});
