import { LOCALES } from "~/constants/i18n";

export const SERVICE_ERROR_INFO_TRANSLATIONS = {
  [LOCALES["en-gb"]]: {
    title: "Oops, service temporarily unavailable",
    text: "Please check back again later, we'll have it fixed asap",
  },
  [LOCALES["en-ie"]]: {
    title: "Oops, service temporarily unavailable",
    text: "Please check back again later, we'll have it fixed asap",
  },
  [LOCALES["en-us"]]: {
    title: "Oops! Service temporarily unavailable",
    text: "Please check back again later: we'll get it fixed ASAP",
  },
  [LOCALES["cs-cz"]]: {
    title: "Je nám líto, služba je dočasně nedostupná",
    text: "Zkuste to znovu později, opravíme to co nejdříve",
  },
  [LOCALES["de-de"]]: {
    title: "Ups – dieser Service ist vorübergehend nicht verfügbar.",
    text: "Bitte versuche es später noch einmal. Wir arbeiten auf Hochtouren, um den Fehler zu beheben.",
  },
  [LOCALES["de-at"]]: {
    title: "Ups – dieser Service ist vorübergehend nicht verfügbar.",
    text: "Bitte versuche es später noch einmal. Wir arbeiten auf Hochtouren, um den Fehler zu beheben.",
  },
  [LOCALES["es-es"]]: {
    title: "Vaya, el servicio no está disponible temporalmente",
    text: "Por favor, inténtalo de nuevo más tarde. Lo arreglaremos lo antes posible.",
  },
  [LOCALES["es-us"]]: {
    title: "Vaya, el servicio no está disponible temporalmente",
    text: "Por favor, inténtalo de nuevo más tarde. Lo arreglaremos lo antes posible.",
  },
  [LOCALES["fr-fr"]]: {
    title: "Oups ! Ce service est temporairement indisponible.",
    text: "Reviens sur cette page un peu plus tard. Nous règlerons le problème dès que possible.",
  },
  [LOCALES["fr-be"]]: {
    title: "Oups ! Ce service est temporairement indisponible.",
    text: "Reviens sur cette page un peu plus tard. Nous règlerons le problème dès que possible.",
  },
  [LOCALES["it-it"]]: {
    title: "Ops! Il servizio è temporaneamente non disponibile",
    text: "Riprova più tardi, sarà ripristinato al più presto",
  },
  [LOCALES["nl-nl"]]: {
    title: "Oeps, service tijdelijk niet beschikbaar",
    text: "Probeer het later opnieuw, we lossen het zo snel mogelijk op",
  },
  [LOCALES["nl-be"]]: {
    title: "Oeps, service tijdelijk niet beschikbaar",
    text: "Probeer het later opnieuw, we lossen het zo snel mogelijk op",
  },
  [LOCALES["pl-pl"]]: {
    title: "Ups! Usługa chwilowo niedostępna",
    text: "Spróbuj ponownie później. Postaramy się jak najszybciej naprawić usterkę.",
  },
  [LOCALES["pt-pt"]]: {
    title: "Ups. Serviço temporariamente indisponível",
    text: "Verifica novamente mais tarde. Vamos corrigi-lo o mais rapidamente possível",
  },
  [LOCALES["sl-si"]]: {
    title: "Ojoj, storitev trenutno ni na voljo.",
    text: "Poskusite znova pozneje, hitro bomo uredili zadevo.",
  },
  [LOCALES["ro-ro"]]: {
    title: "Ne pare rău, serviciul este temporar indisponibil",
    text: "Te rugăm să verifici din nou mai târziu, vom remedia problema cât de curând",
  },
  [LOCALES["sk-sk"]]: {
    title: "Ľutujeme, služba je dočasne nedostupná",
    text: "Skúste to znova neskôr. Vyriešime to čo najskôr.",
  },
  [LOCALES["hu-hu"]]: {
    title: "Hoppá, a szolgáltatás átmenetileg nem érhető el",
    text: "Kérjük, nézzen vissza később, mihamarabb kijavítjuk",
  },
  [LOCALES["el-gr"]]: {
    title: "Ωχ, η υπηρεσία δεν είναι διαθέσιμη προσωρινά",
    text: "Ελέγξτε ξανά αργότερα, θα το διορθώσουμε το συντομότερο",
  },
};

const SERVICE_ERROR_INFO_PREFIX = "ServiceErrorInfo";

export const SERVICE_ERROR_INFO_CLASSES = {
  title: `${SERVICE_ERROR_INFO_PREFIX}-title`,
  text: `${SERVICE_ERROR_INFO_PREFIX}-text`,
};
